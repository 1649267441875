/**
 * @Author: VBernasconi
 * Implement the template for the home page
 * The home page holds the links to the different tasks
 * The tasks are represented by their corresponding image stored in the
 * assets folder.
 */
<template>
  <div id="app">
    <div class="grid-x grid-padding-x">
      <div class="large-offset-1 small-10 cell align-center">
        <p class="papa_welcome text-justify">
          Welcome to the Pose Annotation Project for Artworks.
          As the past few years saw the multiplication of projects at
          the intersection of computer vision and art history, we denote
          a rising interest for body pose recognition on artworks.
          The challenge itself, called Human Pose Estimation (HPE), benefits
          from great advancement in the field of computer vision thanks to the
          use of machine learning methods. Trained on 'real images', photographs
          gathered from the internet, these machine learning models unfortunately
          do not show their full potential on artistic material. We therefore need
          proper training datasets for body pose estimation on artworks in order to
          enhance the work of art historians and digital art history. To achieve this goal,
          the Pose Annotation Project for Artworks (PAPA) was created. A project
          where we need YOU to contribute to the future of digital art history
          and computer vision and to annotate a dataset of paintings through the selection
          of a task bellow.
        </p>
      </div>
    </div>
    <div class="grid-x grid-margin-x align-center">
        <div v-if="this.segmentationAmount !== 0" class="medium-3 small-12 cell image-hover-wrapper">
          <a href="/segmentation" class="thumbnail">
            <img :src="require('@/assets/images/bh000002_seg.jpg')" alt="Segmentation annotation"/>
            <span class="image-hover-wrapper-reveal">
              <p>Segmentation<br>{{this.segmentationAmount}}</p>
            </span>
          </a>
        </div>
      <div v-else class="medium-3 small-12 cell image-hover-wrapper">
          <a class="thumbnail">
            <img :src="require('@/assets/images/bh000002_seg.jpg')" alt="Segmentation annotation"/>
            <span class="image-hover-wrapper-reveal">
              <p>Segmentation<br>{{this.segmentationAmount}}</p>
            </span>
          </a>
      </div>
        <div v-if="this.keyImageAmount !== 0"
             class="medium-3 small-12 cell image-hover-wrapper">
          <a href="/keypoints" class="thumbnail">
            <img :src="require('@/assets/images/bh000002_kps.png')" alt="Keypoints annotation"/>
            <span class="image-hover-wrapper-reveal">
              <p>Keypoints<br>{{this.keyImageAmount}}</p>
            </span>
          </a>
        </div>
      <div v-else
           class="medium-3 small-12 cell image-hover-wrapper">
          <a class="thumbnail">
            <img :src="require('@/assets/images/bh000002_kps.png')" alt="Keypoints annotation"/>
            <span class="image-hover-wrapper-reveal">
              <p>Keypoints<br>{{this.keyImageAmount}}</p>
            </span>
          </a>

      </div>
        <div v-if="this.imageAmount !== 0"
        class="medium-3 small-12 cell image-hover-wrapper">
          <a href="/correction" class="thumbnail">
            <img :src="require('@/assets/images/bh000002.jpg')" alt="Correction"/>
            <span class="image-hover-wrapper-reveal">
              <p>Correction<br>{{this.imageAmount}}</p>
            </span>
          </a>
        </div>
      <div v-else class="medium-3 small-12 cell image-hover-wrapper">
          <a class="thumbnail">
            <img :src="require('@/assets/images/bh000002.jpg')" alt="Correction"/>
            <span class="image-hover-wrapper-reveal">
              <p>Correction<br>{{this.imageAmount}}</p>
            </span>
          </a>
      </div>
    </div>
  </div>
  <router-view/>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import {VUE_APP_BASE_URL} from "../../../config/dev.env";

export default {
  name: 'Home',
  data(){
    return{
      imageAmount: null,
      keyImageAmount: null,
      segmentationAmount: null,
    }
  },
  methods:{
    async getAmountOfCorrectionImages(){
      await axios.get(VUE_APP_BASE_URL+"/correctionsnr")
      .then(response => (this.imageAmount = response.data.amount))
      .then(error => console.log(error))
    },
      async getAmountOfKeypointImages(){
      await axios.get(VUE_APP_BASE_URL+"/keypointnr")
      .then(response => (this.keyImageAmount = response.data.amount))
      .then(error => console.log(error))
    },
    async getSegmentationAmount(){
      await axios.get(VUE_APP_BASE_URL+"/segmentationnr")
      .then(response => (this.segmentationAmount = response.data.amount))
      .then(error => console.log(error))
    }
  },
  mounted() {
    this.getAmountOfCorrectionImages();
    this.getAmountOfKeypointImages();
    this.getSegmentationAmount();
    setInterval(() => {
      this.getAmountOfCorrectionImages();
      this.getAmountOfKeypointImages();
      this.getSegmentationAmount();
    }, 2000)

  },
};
</script>
